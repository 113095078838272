export const switchIcons = (theme: 'classic' | 'orca-noir'): void => {
  const icoIconLink = (document.querySelector('link[rel="icon"][sizes="48x48"]') || document.createElement('link')) as HTMLLinkElement;
  const svgIconLink = (document.querySelector('link[rel="icon"][type="image/svg+xml"]') || document.createElement('link')) as HTMLLinkElement;
  const appleIconLink = (document.querySelector('link[rel="apple-touch-icon"]') || document.createElement('link')) as HTMLLinkElement;
  const manifestLink = (document.querySelector('link[rel="manifest"]') || document.createElement('link')) as HTMLLinkElement;
  switch (theme) {
    case 'classic':
      icoIconLink.href = '/classic-icons/favicon.ico';
      svgIconLink.href = '/classic-icons/favicon.svg';
      appleIconLink.href = '/classic-icons/apple-touch-icon.png';
      manifestLink.href = '/classic-icons/manifest.json';
      break;
    case 'orca-noir':
      icoIconLink.href = '/orca-noir-icons/favicon.ico';
      svgIconLink.href = '/orca-noir-icons/favicon.svg';
      appleIconLink.href = '/orca-noir-icons/apple-touch-icon.png';
      manifestLink.href = '/orca-noir-icons/manifest.json';
      break;
  }
}
